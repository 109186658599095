<template>
  <div :class="[!$store.state.user.elderModel ? 'layout' : 'ElderLayout']">
    <van-nav-bar
      :title="tit"
      :right-text="rtext"
      :left-arrow="rtext2"
      fixed
      v-if="hd"
    >
      <template #right>
        <slot name="rt"></slot>
      </template>
      <template #left>
        <slot name="le"></slot>
      </template>
      <template #title>
        <slot name="title"></slot>
      </template>
    </van-nav-bar>
    <div class="main">
      <slot></slot>
    </div>
    <van-tabbar
      class="tabbar"
      v-model="active"
      v-if="ft"
      route
      active-color="#10955b"
    >
      <van-tabbar-item class="tabbar-item" icon="home-o" to="/home">
        <span>首 页</span>
        <template #icon="props">
          <img
            :src="
              props.active
                ? require('@/assets/img/layout/home-chang-2x.png')
                : require('@/assets/img/layout/home-2x.png')
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item class="tabbar-item" to="/jun-chuang">
        <span>军 创 圈</span>
        <template #icon="props">
          <img
            :src="
              props.active
                ? require('@/assets/img/layout/circle-chang-2x.png')
                : require('@/assets/img/layout/circle-2x.png')
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item class="tabbar-item" to="/me">
        <span>我 的</span>

        <template #icon="props">
          <div class="my-dot" v-if="$store.state.user.userNews != 0"></div>
          <img
            :src="
              props.active
                ? require('@/assets/img/layout/me-chang-2x.png')
                : require('@/assets/img/layout/me-2x.png')
            "
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getItem } from '@/utils/auth'
export default {
  name: 'Layout',
  props: {
    tit: {
      // 是否必传
      required: false,
      // 接受的类型
      type: String,
      // 默认值
      default: ''
    },
    hd: {
      type: Boolean,
      default: false
    },
    ft: {
      type: Boolean,
      default: true
    },
    rtext: {
      type: String,
      default: ''
    },
    rtext2: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      active: -1
    }
  },
  created() {
    if (getItem('Admin-Token')) {
      this['user/getlistByUser']()
    }
  },

  methods: {
    handlChange() {},
    ...mapActions(['user/getlistByUser']) // 引入方法
  }
}
</script>

<style lang="less" scoped>
.van-nav-bar {
  box-sizing: border-box;
  width: 100vw !important;
  height: 44px;
  background: #10955b;
  overflow: hidden;
  color: #ffffff;
  /deep/ .van-nav-bar__title {
    font-size: 18px;
    color: #ffffff;
  }
}
.van-tabbar {
  box-sizing: border-box !important;
  height: 65px !important;
  padding: 10px 0;
}
.main {
  padding-top: 44px;
  padding-bottom:85px;
}
.my-dot {
  position: absolute;
  top: -3px;
  left: 15px;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 10px;
}
.ElderLayout {
  .van-nav-bar {
    box-sizing: border-box;
    width: 100vw !important;
    height: 44px;
    background: #10955b;
    overflow: hidden;
    color: #ffffff;
    /deep/ .van-nav-bar__title {
      font-size: 20px;
      font-weight: 700;
      color: #ffffff;
    }
  }
  .tabbar {
    box-sizing: border-box !important;
  height: 85px !important;
  padding: 20px 0;
    img {
      width: 30px;
      height: 30px;
    }
    span {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      color: #10955b;
      letter-spacing: 0.02px;
    }
  }
}
</style>