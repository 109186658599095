
import request from '@/utils/request'

export function articleList(params) {
    return request({
        url: '/lowcode/online/cgform/api/getData/3af1227981de4980b4ac1b4d62d98a84',
        method: 'get',
        params
    })
}
// export function articleItem(url) {
//     return request({
//         url: `/lowcode/online/cgform/api/form/3af1227981de4980b4ac1b4d62d98a84/${url}`,
//         method: 'get',
//     })

// }
export function articleItem(url) {
    return request({
        url: `/lowcode/bizOpportunityActivity/detail/${url}`,
        method: 'get',
    })

}
// export function getisApply (data) {
//     return request({
//         url: '/lowcode/bizOpportunityActivity/detail',
//         method: 'post',
//         data
//     })

// }
export function openApply(data) {
    return request({
        url: '/lowcode/bizActivityReserve/add',
        method: 'POST',
        data
    })

}
export function pageviewActivity(params) {
    return request({
        url: '/lowcode/bizOpportunityActivity/activityView',
        method: 'get',
        params
    })

}

