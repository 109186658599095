<template>
 <div>
<activityListDetailsNormal v-if="!$store.state.user.elderModel"/>
<activityListDetailsElder  v-else />
 </div>
</template>

<script>
import activityListDetailsNormal from './activity-listDetails-normal.vue'
import activityListDetailsElder from './activityListDetails-elder.vue'
 export default {
   name: '',
   components: {
activityListDetailsNormal,
activityListDetailsElder
   },
   data () {
     return {

     }
   },
   computed: {

   },
   created() {

   },
   mounted() {

   },
   methods: {

   }
 }
</script>

<style  scoped>

 
</style>
